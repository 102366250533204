import React from 'react'
import Inview from 'components/Inview'
import LineLink from 'components/LineLink'

export default function EmailBlock({ content }) {
  if (!content) return false
  const { title, email } = content
  return (
    <section className='email-block pad-v-standard no-collapse'>
      <Inview className='border fade-in up' />
      <div className='container'>
        <div className='text'>
          <div className='wrap'>
            {title && (
              <Inview className='fade-in up'>
                <h2 dangerouslySetInnerHTML={{ __html: title }} />
              </Inview>
            )}
            {email && (
              <Inview className='fade-in up'>
                <p className='larger'>
                  <LineLink content={{ text: email, email }} />
                </p>
              </Inview>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
