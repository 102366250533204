import React from 'react'
import Inview from 'components/Inview'

export default function LocationsBlock({ content }) {
  return (
    <section className='locations-block pad-v-standard'>
      <div className='container'>
        <div className='locations'>
          {content.map((item, i) => (
            <Location key={i} content={item.node} />
          ))}
        </div>
      </div>
    </section>
  )
}

const Location = ({ content }) => {
  const { city, address } = content
  return (
    <Inview className='location fade-in up'>
      <h2 className='smaller'>{city}</h2>
      <p className='address'>{address}</p>
    </Inview>
  )
}
