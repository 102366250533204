import axios from 'axios'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'

export default function ContactForm() {
  const { locale } = useLocalization()
  const [loading, setLoading] = useState(false)

  const sendEmail = (postData) => {
    setLoading(true)
    let url = process.env.GATSBY_EMAIL_URL
    url += '/contact-form/email.php'
    const isEmailLive =
      process.env.GATSBY_IS_EMAIL_LIVE === 'true' ? true : false
    axios({
      method: 'post',
      url,
      data: {
        postData,
        isEmailLive,
      },
    }).then((response) => {
      console.log(response.data)
      setLoading(false)
      const message = document.querySelector('.confirm-message')
      message.classList.add('show')
      setTimeout(() => {
        message.classList.remove('show')
      }, 3000)
    })
  }

  const onSubmit = (values) => {
    sendEmail(values)
  }

  return (
    <div className='contact-form'>
      <Form
        onSubmit={onSubmit}
        // initialValues={{ userType: "Trade" }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event)
              form.reset()
            }}
            // noValidate
          >
            <div className='fieldRow double'>
              <FieldInputText
                name='nameLast'
                label={tr('formNameLast', locale)}
                required={true}
              />
              <FieldInputText
                name='nameFirst'
                label={tr('formNameFirst', locale)}
                required={true}
              />
            </div>

            <div className='fieldRow double'>
              <FieldInputText
                name='phone'
                label={tr('formPhone', locale)}
                required={true}
              />
              <FieldInputEmail
                name='email'
                label={tr('formEmail', locale)}
                required={true}
              />
            </div>

            <div className='fieldRow double'>
              <FieldInputText
                name='company'
                label={tr('formCompany', locale)}
                required={true}
              />
              <FieldInputText
                name='position'
                label={tr('formPosition', locale)}
                required={true}
              />
            </div>

            <div className='fieldRow'>
              <FieldTextArea
                name='message'
                label={tr('formMessage', locale)}
                required={true}
              />
            </div>

            <div className='submit-wrap is-centered'>
              <p className='confirm-message'>{tr('formConfirm', locale)}</p>
              <div className={`loader-wrap ${loading ? 'is-loading' : ''}`}>
                <div
                  className='form-loader'
                  style={{ backgroundImage: 'url(/images/ui/loader.svg)' }}
                />
                <input
                  className='component-button-link'
                  type='submit'
                  value={tr('formSubmit', locale)}
                  disabled={loading}
                />
              </div>
            </div>
          </form>
        )}
      />
    </div>
  )
}

const FieldInputText = ({ name, label, required = false }) => {
  return (
    <div className='field'>
      {/* <label>{label}</label> */}
      <Field
        name={name}
        placeholder={label}
        component='input'
        type='text'
        required={required}
      />
    </div>
  )
}

const FieldInputEmail = ({ name, label, required = false }) => {
  return (
    <div className='field'>
      {/* <label>{label}</label> */}
      <Field
        name={name}
        placeholder={label}
        component='input'
        type='email'
        required={required}
      />
    </div>
  )
}

const FieldTextArea = ({ name, label, required = false }) => {
  return (
    <div className='field'>
      {/* <label>{label}</label> */}
      <Field
        name={name}
        placeholder={label}
        component='textarea'
        required={required}
      />
    </div>
  )
}
