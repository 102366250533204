import React from 'react'
import { graphql } from 'gatsby'
// import BannerTitleOnlyBlock from 'components/blocks/BannerTitleOnlyBlock'
import LocationsBlock from 'components/blocks/LocationsBlock'
import ContactFormBlock from 'components/blocks/ContactFormBlock'
import EmailBlock from 'components/blocks/EmailBlock'

export default function ContactPage({ data }) {
  const page = data.strapiContactPage
  const { title, emailBlock } = page
  // const customBanner = { pretitle: title }
  const locations = data.allStrapiLocation.edges
  return (
    <div className='page-contact'>
      {/* <BannerTitleOnlyBlock content={customBanner} /> */}
      <LocationsBlock content={locations} />
      <EmailBlock content={emailBlock} />
      <ContactFormBlock />
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiContactPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
      emailBlock {
        title
        email
      }
    }
    allStrapiLocation(
      filter: { locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      edges {
        node {
          city
          address
        }
      }
    }
  }
`
