import React from 'react'
import ContactForm from 'components/forms/ContactForm'

export default function ContactFormBlock() {
  return (
    <div className='contact-form-block container pad-v-standard'>
      <ContactForm />
    </div>
  )
}
